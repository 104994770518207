//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MpTable from "@/components/Dashboard/QueriesSelection/MpTable.vue";
import EntitySearch from "@/components/EntitySearch.vue";
import AppTabs from "@/components/AppTabs.vue";
export default {
  name: "QueriesSelection",
  props: {
    selectedMarketplace: {
      type: Object
    }
  },
  components: {
    MpTable,
    AppTabs,
    EntitySearch
  },
  data() {
    const tabs = [{
      id: 2,
      title: "按产品"
    }, {
      id: 3,
      title: "按需求"
    }, {
      id: 1,
      title: "按类别"
    }];
    return {
      tabs,
      tabs_model: tabs[0],
      searchCategoryValue: "",
      searchSkuValue: "",
      searchQueriesValue: "",
      categoryId: 0
    };
  },
  methods: {
    async searchLoader(query) {
      const result = await this.$store.dispatch("category/getCategoryByName", {
        query,
        mp: this.selectedMarketplace.id
      });
      this.categoryId = result[0].c_id;
      return result;
    },
    searchCategoryValueChange(value) {
      this.searchCategoryValue = value;
    },
    searchSkuValueChange(value) {
      this.searchSkuValue = value;
    },
    searchQueriesValueChange(value) {
      this.searchQueriesValue = value;
    },
    onSelectSearchCategoryValue(value) {
      this.categoryId = value.c_id;
      this.searchCategoryValue = value.c_name;
    },
    search() {
      let query = {};
      if (this.tabs_model.id === 1) {
        if (this.searchCategoryValue != "") {
          query.searchCategoryValue = this.searchCategoryValue;
          query.categoryId = this.categoryId;
        }
      }
      if (this.tabs_model.id === 2) {
        if (this.searchSkuValue != "") {
          query.searchSkuValue = this.searchSkuValue;
        }
      }
      if (this.tabs_model.id === 3) {
        if (this.searchQueriesValue != "") {
          query.searchQueriesValue = this.searchQueriesValue;
        }
      }
      const routeData = this.$router.resolve({
        name: "QueriesSelection",
        params: {
          mp: this.selectedMarketplace.id
        },
        query
      });
      window.open(routeData.href, "_blank");

      // this.$router.push({
      //     name: "QueriesSelection",
      //     params: { mp: this.selectedMarketplace.id },
      //     query,
      // });
      // this.$access.hasOrThrow("queriesSelection");
      // if (this.tabs_model.id === 1) {
      //     this.categorySearchId = this.categoryId;
      // }
      // if (this.tabs_model.id === 2) {
      //     this.skuList = this.skuArray;
      // }
      // if (this.tabs_model.id === 3) {
      //     this.queriesList = this.queriesArray;
      // }
      // this.scrollToTable();
    }
  },

  computed: {
    skuArray() {
      return this.searchSkuValue.split("\n").filter(item => item !== "");
    },
    queriesArray() {
      return this.searchQueriesValue.split("\n").filter(item => item !== "");
    },
    tableKey() {
      return `${this.selectedMarketplace.id}`;
    },
    reportSettings() {
      return {
        mp: this.selectedMarketplace.id
      };
    },
    disabled() {
      if (this.tabs_model.id === 1) {
        return this.categoryId === 0;
      }
      if (this.tabs_model.id === 2) {
        return !this.skuArray.length > 0;
      }
      if (this.tabs_model.id === 3) {
        return !this.queriesArray.length > 0;
      }
      return true;
    }
  },
  watch: {
    selectedMarketplace() {
      this.searchCategoryValue = "";
      this.searchSkuValue = "";
      this.searchQueriesValue = "";
      this.categoryId = 0;
    }
    // categoryId(value) {
    //     console.log(value);
    // },
  }
};