//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalAddListM from "@/components/modals/ModalAddListM.vue";
import ModalListmImport from "@/components/modals/ModalListmImport.vue";
import ListMsTable from "@/components/ListM/ListMsTable.vue";
import ListMsWrapper from "@/components/ListM/Wrapper.vue";
export default {
  name: "PositionMonitoring",
  components: {
    ListMsTable,
    ModalAddListM,
    ModalListmImport,
    ListMsWrapper
  },
  data() {
    return {
      refreshKey: 0,
      hasItems: this.$store.state.user.user ? null : false,
      hasItemsLoaded: false
    };
  },
  async created() {
    const result = await this.$store.dispatch("listM/getListMs", {
      page: 1
    });
    //console.log(result);
    this.hasItems = !!result.items.length;
    this.hasItemsLoaded = true;
  },
  methods: {
    onAdded(item) {
      this.refreshKey++;
      this.hasItems = true;
      setTimeout(() => {
        this.$refs.modalListmImport.open({
          item,
          freshList: true
        });
      }, 100);
    },
    onCreateList() {
      this.$access.hasOrThrow("listMs");
      this.$refs.modalAddListM.open();
    }
  },
  computed: {
    reportSettings() {
      return {};
    },
    tableKey() {
      return this.refreshKey;
    }
  }
};