//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CalendarMixin from "@/mixins/Calendar";
import MyShopsMixin from "@/mixins/MyShops";
import AppSelect from "@/components/AppSelect.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import EntityCharts from "@/components/EntityCharts";
import { getEntityCellsConfig } from "@/utils/enums";
import HasTokens from "@/components/HasTokens";
export default {
  name: "MyShops",
  mixins: [CalendarMixin, MyShopsMixin],
  components: {
    AppSelect,
    AppSelectMulti,
    EntityCharts,
    HasTokens
  },
  data() {
    const localStorageCalendar = JSON.parse(localStorage.getItem("calendar"));
    if (localStorageCalendar) {
      this.$store.dispatch("connections/updateCalendar", localStorageCalendar);
    }
    return {
      calendarInternal: null,
      calendar: this.$store.state.connections.calendar
    };
  },
  methods: {
    async loadOverview() {
      var _total, _dynamic, _total2;
      let dynamic = null;
      let total = null;
      if (this.marketplace.title === "Wildberries") {
        dynamic = await this.$store.dispatch("connections/getWbHighlightsByDays", this.reportSettings);
        total = await this.$store.dispatch("connections/getWbHighlights", this.reportSettings);
      }
      if (this.marketplace.title === "OZON") {
        dynamic = await this.$store.dispatch("connections/getOzHighlightsByDays", this.reportSettings);
        total = await this.$store.dispatch("connections/getOzHighlights", this.reportSettings);
      }

      //
      // // ozon api fix
      // if (total?.data[3].id === "sum_finished_price_return_sales") {
      //   total.data[3].id = "sum_price_return_sales"
      // }
      //
      //wb api fix
      const index = (_total = total) === null || _total === void 0 ? void 0 : _total.data.findIndex(item => item.id === "sum_sales");
      if (index >= 0) {
        total.data[index].id = "sum_finished_price_sales";
      }
      let res = {};
      const keys = Object.keys((_dynamic = dynamic) === null || _dynamic === void 0 ? void 0 : _dynamic.data[0]);
      (_total2 = total) === null || _total2 === void 0 || _total2.data.forEach((item, index) => {
        res = {
          ...res,
          [keys[index + 2]]: {
            total: Number(item.value).toFixed(0),
            dynamic: dynamic.data.map(i => {
              return i[`${item.id}`];
            })
          }
        };
      });
      return res;
    }
  },
  computed: {
    cells() {
      return this.$store.state.connections.marketplace.title === "Wildberries" ? getEntityCellsConfig("shopWb") : getEntityCellsConfig("shopOzon");
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      const dates = this.calendar.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      return {
        date: dates[0],
        date2: dates[1],
        tokens: this.selectedTokens.map(item => item.id),
        page_size: 10
      };
    }
  },
  watch: {
    calendar() {
      this.$store.dispatch("connections/updateCalendar", this.calendar);
      localStorage.setItem("calendar", JSON.stringify(this.calendar));
    }
  }
};