/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Categories.vue?vue&type=template&id=03652d4e&scoped=true&"
import script from "./Categories.vue?vue&type=script&lang=js&"
export * from "./Categories.vue?vue&type=script&lang=js&"
import style0 from "./Categories.vue?vue&type=style&index=0&id=03652d4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03652d4e",
  null
  
)

export default component.exports