//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalAddListM from "@/components/modals/ModalAddListM.vue";
import ModalListmImport from "@/components/modals/ModalListmImport.vue";
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    onCreateList() {
      this.$access.hasOrThrow("listMs");
      this.$refs.modalAddListM.open();
    },
    onAdded(item) {
      setTimeout(() => {
        this.$refs.modalListmImport.open({
          item,
          freshList: true,
          onComplete() {
            if (this.$route.name === "ListMs") {
              window.document.location.reload();
            } else {
              this.$router.push({
                name: "ListMs"
              });
            }
          }
        });
      }, 100);
    }
  },
  components: {
    ModalAddListM,
    ModalListmImport
  }
};