//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CategoryPath from "@/components/Category/Path";
import { FadeTransition } from "vue2-transitions";
export default {
  components: {
    CategoryPath,
    FadeTransition
  },
  props: {
    // список категорий, привязанных к продукту. должны содержать c_id_path и position
    productCategories: {
      type: Array,
      required: true
    },
    // исходные 产品类别. каждая часть пути развертывается с помощью этих данных. должны содержать c_id и c_name
    baseCategories: {
      required: true
    },
    routeParams: {
      type: Object,
      default: () => {
        return {};
      }
    },
    routeQuery: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      showPopup: false,
      scrollSettings: {
        suppressScrollX: true,
        suppressScrollY: false,
        wheelPropagation: false
      }
    };
  },
  computed: {
    mainCategory() {
      return this.productCategories[0] || null;
    },
    popupCategories() {
      return this.productCategories.slice(1);
    }
  },
  methods: {
    onClickOutside() {
      this.showPopup = false;
    }
  }
};