//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { getMonitorListM, getMarketplacesList } from "@/utils/enums";
export default {
  components: {
    DataTable
  },
  props: {
    configurable: {
      type: Boolean,
      default: false
    },
    paginatable: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    async tableLoader({
      query
    }) {
      const result = await this.$store.dispatch("listM/getListMs", query);
      if (query.page && query.page === 1) {
        this.$emit("update:hasItems", !!result.items.length);
      }

      ////alert('src/components/ListM/ListMsTable.vue: result.total='+JSON.stringify(result.total))

      this.$emit("update:ListMsTotalRow", result.total);
      return result;
    }
  },
  computed: {
    columns() {
      //let position=0;

      let columns = [{
        title: "商店名称",
        //position: 1,
        show: true,
        name: "title",
        width: 250,
        type: "link",
        getLink(item) {
          return {
            route: {
              name: "ListMView",
              params: {
                id: item.id
              }
            },
            text: item.title,
            target: "_blank"
          };
        }
      }, {
        title: "创建日期",
        //position: 2,
        show: true,
        name: "date",
        width: 140,
        type: "date"
      }, {
        title: "上一次更改",
        //position: 3,
        show: true,
        name: "date_last_notify_string",
        width: 140,
        type: "text"
      }, {
        title: "类型",
        //position: 3,
        show: true,
        name: "monitor",
        getText: v => getMonitorListM()[v],
        width: 240
      }, {
        title: "市场",
        //position: 4,
        show: true,
        name: "mp",
        getText: v => getMarketplacesList()[v],
        width: 120
      }, {
        title: "数量",
        //position: 5,
        show: true,
        name: "count",
        width: 100
      }, {
        title: "描述",
        //position: 6,
        show: true,
        name: "description"
      }, {
        title: "",
        //position: 7,
        show: true,
        name: "_actions",
        type: "actions",
        actions: [{
          title: "删除",
          action: (item, {
            refresh
          }) => {
            this.$emit("remove", {
              item,
              context: {
                refresh
              }
            });
          }
        }, {
          title: "编辑",
          action: (item, {
            refresh
          }) => {
            this.$emit("edit", {
              item,
              context: {
                refresh
              }
            });
          }
        }],
        width: 60
      }];
      if (this.small) {
        columns = columns.filter(column => {
          return column.name === "title" || column.name === "date_last_notify_string" || column.name === "monitor";
        });
      }
      return columns;
    }
  }
};