//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalAddList from "@/components/modals/ModalAddList.vue";
import ModalListImport from "@/components/modals/ModalListImport.vue";
export default {
  props: {
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    onCreateList() {
      this.$access.hasOrThrow("lists");
      this.$refs.modalAddList.open();
    },
    onAdded(item) {
      setTimeout(() => {
        this.$refs.modalListImport.open({
          item,
          freshList: true,
          onComplete() {
            if (this.$route.name === "Lists") {
              window.document.location.reload();
            } else {
              this.$router.push({
                name: "Lists"
              });
            }
          }
        });
      }, 100);
    }
  },
  components: {
    ModalAddList,
    ModalListImport
  }
};